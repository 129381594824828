import React, { useEffect, useState } from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Slider, { NextSlide, CurrentSlide, MobileNav } from '../components/slider'
import IconWifi from '../images/icon-wifi.inline.svg'
import IconTv from '../images/icon-tv.inline.svg'
import IconTable from '../images/icon-table.inline.svg'
import IconChair from '../images/icon-chair.inline.svg'
import AnimatedArrow from '../components/animated-arrow'
import Pattern from '../images/title-pattern.inline.svg'

import '../styles/pages/events.scss'

const MainSection = ({ data }) => {
  return (
    <section className="main-section">
      <div className="wrapper grid grid-cols-[7%_86%_7%]">
        <h3 className="title hidden xl:block">{data.pageTitle}</h3>
        <div className="content-pre-slider">
          <h3 className="title xl:hidden">{data.pageTitle}</h3>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.contentPreSlider }} />
          <Pattern className="hidden mt-10 md:block md:mt-6 xl:mt-12" />
        </div>
        <Slider carousel={data.eventsSlider} reversed>
          <NextSlide sliderId="first" />
          <CurrentSlide sliderId="first" />
          <MobileNav sliderId="first" />
        </Slider>

        <div className="content-post-slider">
          <div className="amenities">
            <h2 className="amenities-title">Amenities</h2>
            <ul>
              <li className="amenity">
                <IconTv className="text-tuya-slate mr-7" />
                Wide Display TV
              </li>
              <li className="amenity">
                <IconChair className="text-tuya-slate mr-7" />
                200 people seated
              </li>
              <li className="amenity">
                <IconTable className="text-tuya-slate mr-7" />
                400 people standing
              </li>
              <li className="amenity">
                <IconWifi className="text-tuya-slate mr-7" />
                WiFi Connection
              </li>
            </ul>
          </div>
          <div className="content" dangerouslySetInnerHTML={{ __html: data.contentPostSlider }} />
          <Link to="/contact" className="btn-cta ">
            Get in touch with us!
            <AnimatedArrow className="h-3 text-tuya-dark mt-2" />
          </Link>
        </div>
      </div>
    </section>
  )
}

const EventsPage = ({ data: graphqlData, location }) => {
  const data = graphqlData.datoCmsEventsPage

  return (
    <Layout className="events-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Events" />
      <MainSection data={data} />
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsEventsPage {
      pageTitle
      contentPostSlider
      contentPreSlider
      eventsSlider {
        id
        image {
          sizes {
            src
            srcSet
          }
        }
      }
    }
  }
`

export default EventsPage
